<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Incidencias
            </div>

            <v-expansion-panels v-model="activePanels">
                <v-expansion-panel style="background-color: #F5F5F5" @click.prevent="toogleFilters">
                    <v-expansion-panel-header>
                        <span class="header">Filtros</span>
                        <template v-slot:actions>
                            <v-btn icon @click.stop="toogleFilters">
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form :disabled="loading">
                            <v-row>
                                <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                                    <DateFind label="Desde" v-model="filtros.FechaDesde"></DateFind>
                                </v-col>

                                <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                                    <DateFind label="Hasta" v-model="filtros.FechaHasta"></DateFind>
                                </v-col>

                                <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                                    <ProovedoresArtProvFind class="mb-n7" ref="provCtrl" 
                                        v-model="filtros.Proveedor" label="Proveedor"
                                        :dirId="this.dirId">
                                    </ProovedoresArtProvFind>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            

            <v-btn
            @click="openCreateDialog"
            color="primary"
            class="mt-5 mb-3">
            Nueva
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100],disablePagination:loading, disableItemsPerPage: loading}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditar"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense
                >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Fecha="{ item }">
                    {{ formatDate(item.Fecha) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Enviado="{ item }">
                    <v-simple-checkbox v-model="item.Enviado" disabled></v-simple-checkbox>
                </template>
            </v-data-table>
        </v-container>

        <!-- Modal new -->
        <v-dialog
          v-model="showCreateDialog"
          transition="dialog-bottom-transition"
          persistent eager
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              NUEVA INCIDENCIA
            </v-card-title>

            <v-card-text class="mt-2">
              <h3>* Selecciona el pedido sobre el que quieres crear la incidencia</h3>
              <PedidosSearch ref="pedidosSearch" :dirId="dirId" @selected="onSelect" :pedidoFiltro="2"></PedidosSearch>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="onBtnClose"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>

import axios from "axios";
import funcs from "../../funcs";
import { mapState } from "vuex";
import DateFind from "../../components/DateFind.vue";
import ProovedoresArtProvFind from "../../components/ArtProv/ProovedoresArtProvFind.vue";
import PedidosSearch from "../../components/PedidosSearch.vue";

export default ({
    components: {
        DateFind, ProovedoresArtProvFind, PedidosSearch
    },
    computed: {
        ...mapState(['empId', 'dirId', 'perId', 'perIdAux', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            activePanels: 0,
            showCreateDialog: false,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Fecha', width: 50, sortable: false, value: 'Fecha' },
                { text: 'Proveedor', width:300, sortable: false, value: 'Pedido.Proveedor' },
                { text: 'Creado por', width:300, sortable: false, value: 'Per.PerNom' },
                { text: 'Enviado', width: 70, sortable: false, value: 'Enviado' }
            ],
            dtOptionsLoaded: false,
            filtros: {
                FechaDesde: null,
                FechaHasta: null,
                Proveedor: {}
            }
        }
    },
    watch: {
      options: {
        handler () {
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      filtros: {
        handler () {
          this.loadData();
        },
        deep: true
      },
      loading: {
        handler() {
          if (this.loading) {
            this.$refs.provCtrl.$refs.FindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    mounted() {
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            DirId: this.dirId,
            EmpId: this.empId,
            FechaDesde: this.filtros.FechaDesde,
            FechaHasta: this.filtros.FechaHasta,
            Proveedor: this.filtros.Proveedor != null ? this.filtros.Proveedor.xproveedor_id : null
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/incidencias/pedidos/list", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false
                resolve({
                items,
                total,
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar las incidencias");
            }
          });
        })
      },
      newDataFromApi(pedidoId) {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            DirId: this.dirId,
            PerId: this.perIdAux,
            PedidoId: pedidoId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/incidencias/pedidos/new", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const created = result.data;

            setTimeout(() => {
                this.loading = false
                resolve({
                  created
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al crear la incidencia");
            }
          });
        })
      },

      loadData(){
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items
          this.totalDataRows = data.total
        });
      },
      onClickRowEditar(item){
        this.$router.push('/incidencias-pedido-editar/' + item.IncidenciaPedidoId);
      },
      openCreateDialog() {
        this.$refs.pedidosSearch.loadData();
        this.showCreateDialog = true;
      },
      onBtnClose() {
        this.showCreateDialog = false;
      },
      toogleFilters() {
        this.activePanels = this.activePanels == null ? 0 : undefined;
      },
      onSelect(item)
      {
        if (item == null) return;
        this.newDataFromApi(item.PedidoId)
          .then(data => {
            if (data.created === true)
            {
              this.showCreateDialog = false;
              this.loadData();
            }
          }); 
      },
      formatDate(value)
      {
          return funcs.formatDate(value);
      }
    } 
})
</script>
