<template>
    <div class="px-1 mt-1">
      <v-row>
        <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100],disablePagination:loading, disableItemsPerPage: loading}"
                :server-items-length="totalDataRows"
                :loading="loading"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense
            >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.FechaPedido="{ item }">
                    {{ formatDate(item.FechaPedido) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Enviado="{ item }">
                    <v-simple-checkbox v-model="item.Enviado" disabled></v-simple-checkbox>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Opt="{ item }">
                    <v-btn :loading="loading" color="primary"  @click="selectItem(item)"><v-icon>{{tableIcon}}</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import axios from "axios";
import funcs from "../funcs";
import { mapState } from "vuex";

export default ({
    components: {
    },
    computed: {
        ...mapState(['empId', 'urlRaiz'])
    },
    props: {
      dirId: { type: Number, default: null },
      proveedor: { type: Number, default: null },
      pedidoFiltro: { type: Number, default: null },
      tableIcon: { type: String, default: 'mdi-plus' }
    },
    data () {
        return {
            loading: false,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Fecha', width: 50, sortable: false, value: 'FechaPedido' },
                { text: 'Proveedor', width:300, sortable: false, value: 'Proveedor' },
                { text: 'Creado por', width:300, sortable: false, value: 'PerNom' },
                { text: 'Enviado', width: 70, sortable: false, value: 'Enviado' },
                { text: '', width: 50, sortable: false, value: 'Opt', align: 'end' }
            ],
            dtOptionsLoaded: false
        }
    },
    watch: {
      options: {
        handler () {
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      }
    },
    methods: {
      getDataFromApi() {
        this.loading = true;
        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            DirId: this.dirId,
            EmpId: this.empId,
            Enviado: true,
            Proveedor: this.proveedor,
            PedidoFiltro: this.pedidoFiltro
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/pedidos/list", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false
                resolve({
                  items,
                  total,
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar los pedidos");
            }
          });
        })
      },
      loadData() {
        this.dataRows = [];
        this.totalDataRows = 0;
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items;
          this.totalDataRows = data.items.length;
        });
      },
      selectItem(item)
      {
        this.loading = true;
        this.$emit('selected', item);
      },
      formatDate(value)
      {
          return funcs.formatDate(value);
      }
    },
})
</script>
